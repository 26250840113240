<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>상황별 발생 추이</b>
		</div>
		<!-- Search -->
		<div ref="panelFlat" class="panel panel-flat">
			<div class="panel-body">
				<form action="#" @submit.prevent="search" @keyup.enter.prevent="search">
					<div class="row">
						<div>
							<div class="form-group">
								<!-- 현장 -->
								<label>{{ detailsFieldMap.siteId }}</label>
								<select2 v-model="searchVM.siteId" :disabled="isSiteDisabled">
									<option value="">전체</option>
									<option v-for="item in searchOptions.siteOptions" :key="item.siteId" :value="item.siteId">
										{{ item.siteNm }}
									</option>
								</select2>
							</div>
						</div>
						<div>
							<div class="form-group">
								<!-- 발생구분 -->
								<label>{{ detailsFieldMap.eventType }}</label>
								<select2 v-model="searchVM.eventType">
									<option value="">전체</option>
									<option v-for="item in searchOptions.eventTypeOptions" :key="item.value" :value="item.value">
										{{ item.text }}
									</option>
								</select2>
							</div>
						</div>
						<div>
							<div class="form-group">
								<!-- CCTV -->
								<label>{{ detailsFieldMap.cctvInstallPlaceCont }}</label>
								<select2 v-model="searchVM.cctvInstallPlaceCont">
									<option value="">전체</option>
									<option v-for="item in searchOptions.cctvOptions" :key="item.cctvInstallPlaceCont" :value="item.cctvInstallPlaceCont">
										{{ item.cctvInstallPlaceCont }}
									</option>
								</select2>
							</div>
						</div>
						<div class="checkbox-wrapper">
							<div class="form-group">
								<label style="margin-top: 8px; padding-right: 7px">이력</label>
								<div class="checkbox" style="margin-bottom: 14px">
									<input type="checkbox" id="check" v-model="searchVM.isCheckedCctvAll" />
								</div>
							</div>
						</div>
						<div class="checkbox-wrapper">
							<div class="form-group" style="margin-bottom: 0px; display: flex; justify-content: center">
								<label style="margin-top: 8px; padding-right: 7px">시간별 통계 보기</label>
								<div class="checkbox">
									<input type="checkbox" id="check" v-model="searchVM.ishourlyStatistic" />
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div period>
							<!-- 요청일자 -->
							<div class="form-group" period>
								<label style="margin-right: 10px">{{ detailsFieldMap.period }}</label>
								<input type="date" class="form-control" style="margin-right: 5px" v-model="searchVM.periodStrtDt" :format="'yyyy-MM-dd'" />
								<input
									type="time"
									class="form-control"
									style="margin-right: 10px"
									v-model="searchVM.periodStrtTime"
									:disabled="!searchVM.ishourlyStatistic"
									:format="'HH:mm:ss'"
								/>
								~
								<input
									type="date"
									class="form-control"
									style="margin-left: 10px"
									v-model="searchVM.periodEndDt"
									:format="'yyyy-MM-dd'"
									:min="searchVM.periodStrtDt"
									:max="maxDay"
								/>
								<input
									type="time"
									class="form-control"
									style="margin-left: 5px"
									v-model="searchVM.periodEndTime"
									:disabled="!searchVM.ishourlyStatistic"
									:format="'HH:mm:ss'"
								/>
							</div>
						</div>
						<div class="mb-10 text-right" style="float: right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div id="detailsChart" class="row">
			<div class="col-lg-12">
				<!-- 1. 일별 API 호출(최근N일) -->
				<div class="panel panel-flat">
					<div class="panel-heading">
						<h6 class="panel-title">
							<i class="icon-stats-growth position-left"></i>
							<b>라인 차트</b>
						</h6>
					</div>
					<div class="panel-body">
						<div id="lineChart" style="height: 700px"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Select2 from '@/components/Select2';
import moment from 'moment';
import apiIndex from '../../api/index';
const dangerOccurUrl = apiIndex.dangerOccur;
const objectDangerOccurStatistic = apiIndex.objectDangerOccurStatistic;

let axiosExtention;

export default {
	components: {
		select2: Select2,
	},
	data: () => ({
		pageParam: null,
		searchVM: {
			siteId: '',
			isCheckedCctvAll: false,
			cctvInstallPlaceCont: '',
			periodStrtDt: '',
			periodEndDt: '',
			periodStrtTime: '',
			periodEndTime: '',
			ishourlyStatistic: false, // 시간별 통계 보기 체크 여부
		},
		searchOptions: {}, // 검색 select options
		detailsFieldMap: {
			siteId: '현장',
			eventType: '발생구분',
			cctvInstallPlaceCont: 'CCTV',
			period: '요청일자',
		},
		chartJson: [],
		chartObjectTargetList: [],
		isSiteDisabled: false,
		maxDay: '', //periodEndDt의 지정 가능한 최대 일자
	}),
	computed: {
		//차트
		// API 호출 통계
		charData() {
			return {
				// 차트 x 축 데이터
				dataSource: [],
				// X축
				categoryAxis: {
					// 데이터를 가져올 필드명 세팅
					field: 'dateData',
					// rotation: 화면이 작아졌을 때 세로로 보여줄 거냐..
					// foramt: 값을 보여줄 형태 지정
					// step: 값이 몇 씩 증가하는가..
					labels: { rotation: 'auto', step: 1, font: '10px Arial, Helvetica, sans-serif' },
				},
				// Y축
				valueAxis: [
					// format: y축 데이터를 어떻게 표시할 것인가..
					{
						labels: {
							template: `#if (value == null || value < 1000) {# #= kendo.format('{0}', value) # #} else {# #= kendo.format('{0:N0}', value) # #}#`,
							font: '11px Arial, Helvetica, sans-serif',
						},
					},
				],
				// 툴팁( 마우스 올렸을 때 나오는 조그만 정보 창 )
				tooltip: {
					// 툴팁 표시, 비표시 설정
					visible: true,
					// 툴팁 내용을 어떻게 표시할 것인가..
					template: `#: category #<br/>#: series.name # : #: kendo.toString(value, 'n0') #건`,
				},
				// 차트 원본 데이터(json 배열) 세팅
				series: [],
			};
		},
	},
	watch: {
		// chartJson(newVal) {
		chartJson() {
			//details활성화 시 스크롤 이동처리
			this.$nextTick(function () {
				window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
			});
		},
		'searchVM.siteId': {
			handler(value, oldValue) {
				if (oldValue !== null) {
					this.$axios
						.post(dangerOccurUrl.inqDangerOccurCctvInfo, { siteId: this.searchVM.siteId, isCheckedCctvAll: this.searchVM.isCheckedCctvAll })
						.then(
							function (r) {
								this.searchOptions.cctvOptions = r.data;
								this.searchVM.cctvInstallPlaceCont = '';
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler());
				}
			},
		},
		'searchVM.isCheckedCctvAll': {
			handler() {
				this.$axios
					.post(dangerOccurUrl.inqDangerOccurCctvInfo, { siteId: this.searchVM.siteId, isCheckedCctvAll: this.searchVM.isCheckedCctvAll })
					.then(
						function (r) {
							this.searchOptions.cctvOptions = r.data;
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			},
		},
		//조회기간을 최대 x개월 간으로 하기 위해
		'searchVM.periodStrtDt': {
			handler(value) {
				this.maxDay = moment(value).add(process.env.VUE_APP_MAX_MONTH, 'month').format('YYYY-MM-DD');
			},
		},
		'searchVM.ishourlyStatistic': {
			handler(isChecked) {
				if (isChecked) {
					this.searchVM.periodStrtDt = moment().format('YYYY-MM-DD');
					this.searchVM.periodEndDt = moment().format('YYYY-MM-DD');
					this.searchVM.periodStrtTime = '00:00';
					this.searchVM.periodEndTime = moment().format('HH:mm');
				}
			},
		},
	},
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('objectDangerOccurStatisticPageParam'));
		if (!this.pageParam) {
			location.href = apiIndex.mainUrl;
		}

		axiosExtention = this.$jarvisExtention.axiosExtention;

		// 본사관리자가 아니면, 현장 select box Disabled
		if (this.pageParam.loginUserRolecd !== '00') {
			this.isSiteDisabled = true;
		}
		this.searchVM = {
			siteId: this.pageParam.loginUserSiteId,
			eventType: '',
			isCheckedCctvAll: false,
			cctvInstallPlaceCont: '',
			periodStrtDt: moment().add(-6, 'day').format('YYYY-MM-DD'),
			periodEndDt: moment().format('YYYY-MM-DD'),
			periodStrtTime: '00:00',
			periodEndTime: '00:00',
			ishourlyStatistic: false, // 시간별 통계 보기 체크 여부
		};
		this.searchOptions = {
			siteOptions: this.pageParam.siteOptions,
			eventTypeOptions: [
				{
					value: 'danger',
					text: '위험',
				},
				{
					value: 'warnWork',
					text: '주의',
				},
			],
			cctvOptions: this.pageParam.cctvOptions,
		};
	},
	mounted() {
		this.search();
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('lineChart');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		//유효한 요청일자 기간인지 확인
		isValidPeriod() {
			// 시간별 통계보기 체크 시
			if (this.searchVM.ishourlyStatistic) {
				// 날짜, 시간 값 둘 다 확인
				if (
					this.searchVM.periodStrtDt == null ||
					this.searchVM.periodStrtTime == '' ||
					this.searchVM.periodEndDt == null ||
					this.searchVM.periodEndTime == ''
				) {
					alert('날짜를 입력해주세요');
					return false;
				}

				if (
					new Date(this.searchVM.periodStrtDt + ' ' + this.searchVM.periodStrtTime) >
					new Date(this.searchVM.periodEndDt + ' ' + this.searchVM.periodEndTime)
				) {
					alert('유효한 날짜를 입력해주세요');
					return false;
				}
			} else {
				// 날짜 값만 확인
				if (this.searchVM.periodStrtDt == null || this.searchVM.periodEndDt == null) {
					alert('날짜를 입력해주세요');
					return false;
				}

				if (new Date(this.searchVM.periodStrtDt) > new Date(this.searchVM.periodEndDt)) {
					alert('유효한 날짜를 입력해주세요');
					return false;
				}
			}

			return true;
		},
		search() {
			if (this.isValidPeriod() == false) {
				return;
			}

			// 데이터 초기화
			this.resetChartData();

			// 차트 데이터 불러오기
			// this.loadChartData({
			// 	siteId: this.searchVM.siteId,
			// 	eventType: this.searchVM.eventType,
			// 	cctvInstallPlaceCont: this.searchVM.cctvInstallPlaceCont,
			// 	periodStrtDt: this.searchVM.periodStrtDt,
			// 	periodStrtTime: this.searchVM.periodStrtTime,
			// 	periodEndDt: this.searchVM.periodEndDt,
			// 	periodEndTime: this.searchVM.periodEndTime,
			// 	ishourlyStatistic: this.searchVM.ishourlyStatistic,
			// 	isCheckedCctvAll: this.searchVM.isCheckedCctvAll,
			// });
			this.loadChartData();
		},
		// 데이터 초기화
		resetChartData() {
			this.chartJson = [];
			this.charData.dataSource = [];
			this.charData.series = [];
		},
		// 차트 데이터 불러오기
		loadChartData() {
			this.$axios
				.post(objectDangerOccurStatistic.inqObjectDangerOccurStatisticLineChart, this.searchVM)
				.then(response => {
					this.chartJson = response.data;
					this.createChartData(response.data);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 불러온 데이터 그래프 형식에 맞게 parsing
		async createChartData(data) {
			// 대상 리스트 조회
			this.chartObjectTargetList = await this.$axios
				.post(objectDangerOccurStatistic.inqChartObjectTargetList, {
					eventType: this.searchVM.eventType,
				})
				.then(res => res.data);

			// 대상물-조치대상 개수
			let listSize = this.chartObjectTargetList.length;
			// 대상물-조치대상 개수 리스트
			let dangerOccurCntList = [];
			for (let i = 0; i < listSize; i++) {
				let list = [];
				dangerOccurCntList.push(list);
			}
			// console.log(data);
			data.forEach(d => {
				// 시간별 통계 보기 활성화 시
				if (this.searchVM.ishourlyStatistic) {
					let date = d.dateData.split(' ');
					if (date) {
						if (date[1] != '00:00:00') {
							// 0시가 아니면 시간만 표현
							d.dateData = date[1];
						} else {
							// 0시면 날짜로 표현
							d.dateData = date[0];
						}
					}
				}
				// 날짜
				this.charData.dataSource.push({ dateData: d.dateData });
				// 대상물-조치대상 개수를 각 번호에 쭉 push
				for (let i = 0; i < listSize; i++) {
					dangerOccurCntList[i].push(d.objectTargetDangerCnts[i]);
				}
			});

			let series = [];

			// 그래프 세팅
			for (let i = 0; i < listSize; i++) {
				let line = {
					type: 'line',
					data: dangerOccurCntList[i],
					name: `${this.chartObjectTargetList[i].objectNm}\n${this.chartObjectTargetList[i].targetNm || ''}`,
				};
				series.push(line);
			}

			for (let i = 0; i < listSize; i++) {
				this.charData.series.push(series[i]);
			}

			this.drawChart();
		},
		// 차트 그리기
		drawChart() {
			$('#lineChart').kendoChart({
				dataSource: this.charData.dataSource,
				categoryAxis: this.charData.categoryAxis,
				valueAxis: this.charData.valueAxis,
				series: this.charData.series,
				legend: {
					position: 'right',
				},
				tooltip: this.charData.tooltip,
			});
		},
	},
};
</script>

<style>
span.select2-selection.select2-selection--single[aria-disabled='true'] {
	background: #eee;
}

.checkbox {
	display: inline-block !important;
	margin-left: 0.7em;
}

#rootVM > .panel .panel-body {
	padding: 40px !important;
	margin: 0 !important;
}

#rootVM > .panel .panel-body form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#rootVM > .panel .row {
	display: flex;
	width: 900px;
}

#rootVM > .panel .row > div {
	width: 100%;
}

#rootVM > .panel .row .form-group > span {
	width: 60% !important;
	margin-left: 10px;
}

#rootVM > .panel .row .form-group input {
	display: inline-block;
}

#rootVM > .panel .row > [period] {
	width: 100%;
}

#rootVM > .panel .row:first-child > .checkbox-wrapper {
	width: 200px;
}

#rootVM > .panel .row:first-child > .checkbox-wrapper:last-child {
	width: 500px;
}

#rootVM > .panel .row:last-child .mb-10 {
	width: 200px;
	margin-left: auto;
}

[type='search'] {
	display: inline-block;
	width: 180px;
	margin-right: 5px;
}

[period] [type='date'] {
	width: 150px !important;
}

[period] [type='time'] {
	width: 130px !important;
}

.col-lg-12 {
	padding-right: 0 !important;
}
</style>
